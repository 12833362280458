<template>
  <div>
    <common-header></common-header>
    <div class="warp sx-items">
      <div class="selection-box">
        <div class="item" @click.stop="openPicker()">
          <input
            type="text"
            readonly
            placeholder="下单日期起"
            :value="startTime"
          />
          <div
            class="clear"
            v-show="startTime != ''"
            @click.stop="clearClick('startTime')"
          ></div>
          <i class="icon-black-arrow"></i>
        </div>
        <div class="item" @click.stop="openPicker2()">
          <input
            type="text"
            readonly
            placeholder="下单日期止"
            :value="endTime"
          />
          <div
            class="clear"
            v-show="endTime != ''"
            @click.stop="clearClick('endTime')"
          ></div>
          <i class="icon-black-arrow"></i>
        </div>
      </div>
      <div class="selection-box">
        <div class="item">
          <input type="text" placeholder="姓名、店铺" v-model="searchName" />
        </div>
        <div class="item">
          <input type="number" placeholder="手机号码" v-model="searchPhone" />
        </div>
        <div class="item search-box" @click="searchClick">
          查询
        </div>
      </div>
    </div>
    <div class="order-type-box warp">
      <div class="type-fixed" :style="`top:${isWeiXin() ? 0 : 40}px`">
        <div
          class="btn"
          :class="datas.status == '' ? 'active' : ''"
          @click="changeTab(0, '')"
        >
          全部
        </div>
        <div
          class="btn"
          :class="datas.status == 100 ? 'active' : ''"
          @click="changeTab(1, 100)"
        >
          待支付
        </div>
        <div
          class="btn"
          :class="datas.status == 200 ? 'active' : ''"
          @click="changeTab(2, 200)"
        >
          待发货
        </div>
        <div
          class="btn"
          :class="datas.status == 201 ? 'active' : ''"
          @click="changeTab(3, 201)"
        >
          待收货
        </div>
        <div
          class="btn"
          :class="datas.status == 202 ? 'active' : ''"
          @click="changeTab(4, 202)"
        >
          已完成
        </div>
        <div
          class="btn"
          :class="datas.status == 400 ? 'active' : ''"
          @click="changeTab(5, 400)"
        >
          退款
        </div>
      </div>
    </div>

    <ul
      class="order-lists warp"
      v-infinite-scroll="loadMore"
      infinite-scroll-disabled="loading"
      infinite-scroll-immediate-check="false"
      infinite-scroll-distance="100"
    >
      <li class="item" v-for="(item, index) in listObj.list" :key="index">
        <div class="time-type">
          <div class="time">{{ item.createTime }}</div>
          <div class="type">{{ orderStatus(item.status) }}</div>
        </div>
        <!-- <div class="goods-list" @click="$router.push({path: '/account/orderdetails', query: {id: item.id}})">
          <div class="li" v-for="(item2, index2) in item.orderDetail" :key="index2">
            <img class="goods-img" :src="noImgSet(item2.image)" alt="">
            <div class="goods-info">
              <div class="name">{{item2.name}}</div>
              <div class="ms">{{item2.specName}}</div>
              <div class="price">
                <div class="num">x{{item2.quantity}}</div>
                <div class="money"><span class="unit">￥</span>{{item2.unitPrice}}</div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="commodity-ul">
          <div
            class="commodity-item"
            v-for="(item2, index2) in item.orderDetail"
            :key="index2"
          >
            <img class="commodity-img" :src="noImgSet(item2.image)" alt="" />
            <div class="right">
              <div class="commodity-details">
                <h4 class="name">{{ item2.name }}</h4>
                <p class="commodity-ms">
                  {{ item2.specName }}{{ item2.seckillId ? "（秒杀）" : ""
                  }}{{ item2.remark ? "（已改）" : "" }}
                </p>
              </div>
              <div class="commodity-num-box">
                <div class="num">x{{ item2.quantity }}</div>
                <div class="money">
                  <span class="deposit" v-if="item2.deposit > 0"
                    >(含押金{{ item2.deposit }}元)</span
                  >
                  <span class="unit">￥</span>{{ item2.price + item2.deposit }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="total-box"
          v-if="item.couponAmount || item.deductBalance > 0"
        >
          <div class="item" v-if="item.couponAmount">
            <div class="il">优惠券</div>
            <div class="ir">
              <span class="unit">-￥</span>{{ item.couponAmount }}
            </div>
          </div>
          <div class="item" v-if="item.deductBalance">
            <div class="il">余额抵扣</div>
            <div class="ir">
              <span class="unit">-￥</span>{{ item.deductBalance }}
            </div>
          </div>
        </div>
        <div class="bottom">
          <div class="orderCode">
            订单编号：{{item.orderCode}}
          </div>
          <div class="money">
            <div class="total">
              <!-- <div class="l" v-if="item.originTotalPrice != item.realTotalPrice">
                总价:<span class="del">￥{{item.originTotalPrice}}</span>
              </div> -->
              <div>
                实付款<span class="actual"
                  ><span class="unit">￥</span
                  ><b class="je">{{ item.realTotalPrice }}</b></span
                >
              </div>
            </div>
          </div>
        </div>

        <div class="remark">
            送货信息：{{ item.receiveName + '，' + item.receiveRegionName + '，' + item.receiveAddress + '，'  + item.receivePhone }}
        </div>
        <div class="remark" v-if="item.userRemark">
            备注信息：{{ item.userRemark }}
        </div>
      </li>
    </ul>

    <!-- 支付是否成功start -->
    <PayPop :orderCode="orderCode" :payTypePop="payTypePop"></PayPop>
    <!-- 支付是否成功start -->

    <no-data v-if="nodata"></no-data>
    <load-completion v-if="nomore && listObj.list.length > 4"></load-completion>

    <mt-datetime-picker
      ref="picker"
      type="date"
      year-format="{value} 年"
      month-format="{value} 月"
      :endDate="endDate"
      @confirm="handleConfirm"
      v-model="xzStartTime"
    >
    </mt-datetime-picker>

    <mt-datetime-picker
      ref="picker2"
      type="date"
      year-format="{value} 年"
      month-format="{value} 月"
      class="time2"
      :endDate="endDate"
      @confirm="handleConfirm2"
      v-model="xzEndTime"
    >
    </mt-datetime-picker>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import {
  requestImgUrl,
  isWeiXin,
  noImgSet,
  onBridgeReadyStart,
  DateFormat,
} from "@/utils/common";
import PayPop from "@/components/global/PayPop";
import { orderStatus } from "@/utils/utils";
export default {
  data() {
    this.requestImgUrl = (url, privates) => requestImgUrl(url, privates);
    this.orderStatus = (e) => orderStatus(e);
    this.noImgSet = (e) => noImgSet(e);
    this.isWeiXin = () => isWeiXin();
    return {
      startTime: "",
      endTime: "",
      endDate: new Date(),
      timeStartValue: new Date(),
      xzStartTime: new Date(),
      xzEndTime: new Date(),
      tabIndex: 0,
      loading: false,
      nodata: false,
      nomore: false,
      payTypePop: false,
      datas: {
        pageNum: 1,
        pageSize: 30,
        // 100=已下单，200=待发货（已支付），201=已发货，202=确认收货，300=已取消，400=已完成，500已退款
        status: "",
      },
      orderCode: "",
      listObj: "",
      searchName: "",
      searchPhone: "",
    };
  },
  components: {
    PayPop: PayPop,
  },
  mounted() {
    let datas = this.datas;
    let query = this.$route.query;
    if (query.status) {
      datas.status = query.status;
      this.status = datas;
    }
    if (query.curMonthStartStr && query.curMonthEndStr) {
      this.startTime = query.curMonthStartStr;
      this.endTime = query.curMonthEndStr;
      this.xzStartTime = new Date(query.curMonthStartStr)
      this.xzEndTime = new Date(query.curMonthEndStr);
    }

    this.init(datas);
  },
  methods: {
    clearClick(e) {
      this[e] = "";
    },
    searchClick() {
      this.loading = false;
      this.datas.pageNum = 1;
      this.init({

      });
    },
    openPicker(i) {
      this.$refs.picker.open();
    },
    openPicker2(i) {
      this.$refs.picker2.open();
    },
    handleConfirm(e) {
      this.startTime = DateFormat(e).substring(0, 10);
    },
    handleConfirm2(e) {
      this.endTime = DateFormat(e).substring(0, 10);
    },
    // 再来一单
    onMoreOrder(id) {
      this.getOrderInfo({
        orderId: id,
      }).then((res) => {
        // this.$router.push('/shopping/car')
        this.$toast("已成功添加到购物车");
      });
    },
    // 初始化
    init(obj, add) {
      this.$Indicator.open({
        text: "加载中...",
        spinnerType: "snake",
      });
      obj.endDate = this.endTime;
      obj.startDate = this.startTime;
      obj.phone = this.searchPhone;
      obj.userNickName = this.searchName;
      obj.status = this.datas.status;
      obj.pageNum = this.datas.pageNum;
      obj.pageSize = 15;
      this.promotedOrderPage(obj).then((res) => {
        if (add) {
          let { listObj } = this;
          listObj.list = listObj.list.concat(res.data.list);
          this.listObj = listObj;
        } else {
          this.listObj = res.data;
        }
        this.nodata = this.listObj.list.length > 0 ? false : true;
        this.$Indicator.close();
      });
    },
    // 支付
    mallPay(orderCode, realTotalPrice) {
      if (!isWeiXin()) {
        this.$toast("请在微信中打开页面支付！");
        return false;
      }
      this.$Indicator.open({
        text: "检查支付环境...",
        spinnerType: "snake",
      });
      this.postOrderLink({
        orderCode: orderCode,
        paySource: "WECHAT",
      }).then((res2) => {
        if (isWeiXin()) {
          onBridgeReadyStart(
            JSON.parse(res2.data.payParam),
            (res3) => {
              this.$router.push({
                path: "/shopping/payment",
                query: { orderCode: orderCode, price: realTotalPrice },
              });
            },
            () => {
              this.$toast("取消支付");
            }
          );
        }
        this.$Indicator.close();
      });
    },
    // 确认收货
    confirmReceipt(orderCode) {
      this.$MessageBox({
        title: "确认收到货了吗?",
        message: "为了保证您的售后权益,请收到商品检测无误后再确认收货",
        showCancelButton: true,
        confirmButtonText: "确认收货",
      }).then((res) => {
        if (res == "confirm") {
          this.PutConfirmreceipt({
            orderCode: orderCode,
          }).then((res) => {
            this.init(this.datas);
          });
        }
      });
    },
    // 申请退款
    applyRefund(orderCode) {
      this.$MessageBox({
        title: "温馨提示",
        message: "是否申请退款?",
        showCancelButton: true,
      }).then((e) => {
        if (e == "confirm") {
          this.PutApplyRefund({
            orderCode: orderCode,
          }).then((res) => {
            this.$toast("申请成功");
            setTimeout(() => {
              this.init(this.datas);
            }, 1500);
          });
        }
      });
    },
    // 切换
    changeTab(e, status) {
      this.tabIndex = e;
      let { datas } = this;
      datas.pageNum = 1;
      datas.status = status;
      this.datas = datas;
      this.nomore = false;
      this.loading = false;
      this.listObj = [];
      this.init(datas);
    },
    // 加载下一页
    loadMore() {
      console.log(1);
      this.loading = true;
      let { listObj, datas } = this;
      datas.pageNum = datas.pageNum + 1;
      this.datas = datas;
      if (datas.pageNum > listObj.pages) {
        console.log("到底了");
        this.nomore = true;
      } else {
        this.loading = false;
        this.init(datas, "add");
      }
    },
    // 取消订单
    cancelOrder(orderCode) {
      this.$MessageBox({
        title: "温馨提示",
        message: "是否取消订单?",
        showCancelButton: true,
      }).then((e) => {
        if (e == "confirm") {
          this.PutCancelOrder({
            orderCode: orderCode,
          }).then((res) => {
            this.init(this.datas);
          });
        }
      });
    },
    ...mapActions("account", [
      "promotedOrderPage",
      "PutConfirmreceipt",
      "PutCancelOrder",
      "PutApplyRefund",
    ]),
    ...mapActions("shopping", ["postOrderLink", "getOrderInfo"]),
  },
};
</script>
<style lang="less" scoped>
.clear{
  width: .4rem;
  height: .4rem;
  border-radius: 50%;
  color: #333;
  margin-left: .2rem;
  margin-right: .4rem;
  background-image: url('../../assets/images/img/clear-input.png');
  background-size: 100% 100%;
}
.sx-items{
  padding-top: .2rem;
}
.total-box {
  border-top: 1px solid #f7f7f7;
  padding-top: 0.2rem;
  .unit {
    font-size: 0.24rem;
  }
  .il {
    font-size: 0.26rem;
  }
  .ir {
    font-size: 0.32rem;
    display: flex;
    align-items: flex-end;
    line-height: 1;
  }
  .copus-xx {
    display: flex;
    align-items: center;
    .ir {
      margin-right: 0.1rem;
    }
  }
  .item {
    font-size: 0.28rem;
    color: #333;
    display: flex;
    justify-content: space-between;
    height: 0.5rem;
    align-items: center;
    padding: 0 !important;
  }
  .price {
    margin-top: 0;
    margin-left: 0.1rem;
  }
}
.deposit {
  font-size: 0.24rem;
  color: #bfbfbf;
  margin-right: 0.15rem;
}

.remark{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #f7f7f7;
  font-size: 0.26rem;
  padding: 0.26rem 0;
  color: #727272;
}
.bottom {
  height: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #f7f7f7;
  border-bottom: 1px solid #f7f7f7;
  .orderCode{
    font-size: 0.26rem;
    color: #727272;
  }
  .btn {
    width: 2.15rem;
    height: 0.65rem;
    border-radius: 0.32rem;
    color: #fff;
    text-align: center;
    line-height: 0.65rem;
    font-weight: 700;
  }
  .del {
    text-decoration: line-through;
  }
  .unit {
    font-size: 0.24rem;
  }
  .actual {
    color: #ff7241;
    margin-left: 0.12rem;
    .je {
      font-size: 0.32rem;
      font-weight: normal;
    }
  }
  .total {
    display: flex;
    font-size: 0.26rem;
    color: #727272;
    .l {
      margin-right: 0.2rem;
    }
  }
}
.goods-list {
  background: #fff;
  margin-top: 0.2rem;
  border-radius: 0.1rem;
  overflow: hidden;
  .li {
    display: flex;
    padding-bottom: 0.3rem;
    .goods-info {
      flex: 1;
      .name {
        color: #262626;
        font-size: 0.3rem;
        font-weight: 700;
      }
      .ms {
        font-size: 0.26rem;
        color: #bfbfbf;
        margin-top: 0.05rem;
      }
    }
  }
  .goods-img {
    width: 1rem;
    height: 1rem;
    border-radius: 0.05rem;
    margin-right: 0.3rem;
  }
}
.price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.4rem;
  .num {
    font-size: 0.26rem;
    color: #727272;
  }
  .unit {
    font-size: 0.24rem;
  }
  .money {
    font-size: 0.32rem;
    color: #ff7241;
    font-weight: 700;
  }
}
.order-lists {
  margin-top: 0.2rem;
  .item {
    padding-top: 0.46rem;
    padding-left: 0.36rem;
    padding-right: 0.36rem;
    background: #fff;
    border-radius: 0.1rem;
    margin-bottom: 0.2rem;
  }
}
.time-type {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .time {
    font-size: 0.24rem;
    color: #bfbfbf;
  }
  .type {
    font-size: 0.24rem;
    color: #ff7241;
  }
}
.order-type-box {
  .type-fixed {
    // position: fixed;
    left: 0;
    width: 100%;
    background: #fff;
    display: flex;
    justify-content: space-between;
    height: 1.13rem;
    align-items: center;
    line-height: 1.13rem;
    font-size: 0.3rem;
    color: #262626;
    padding: 0 0.2rem;
    box-shadow: 0 0 0.1rem #e8e8e8;
    border-radius: 0.15rem;
  }
  height: 1.13rem;
  .active {
    color: #ff7241;
    font-weight: 700;
    border-bottom: 0.04rem solid #ff7241;
  }
}
.selection-box .item.search-box {
  flex: 0.4;
  color: #fff;
  justify-content: center !important;
  background: rgba(255, 113, 65, 1); /* Old Browsers */
  background: -moz-linear-gradient(
    left,
    rgba(255, 113, 65, 1) 0%,
    rgba(255, 185, 139, 1) 100%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(255, 113, 65, 1)),
    color-stop(100%, rgba(255, 185, 139, 1))
  ); /* Chrome, Safari4+ */
  background: -webkit-linear-gradient(
    left,
    rgba(255, 113, 65, 1) 0%,
    rgba(255, 185, 139, 1) 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    left,
    rgba(255, 113, 65, 1) 0%,
    rgba(255, 185, 139, 1) 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    left,
    rgba(255, 113, 65, 1) 0%,
    rgba(255, 185, 139, 1) 100%
  ); /* IE 10+ */
  background: linear-gradient(
    to right,
    rgba(255, 113, 65, 1) 0%,
    rgba(255, 185, 139, 1) 100%
  ); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff7141', endColorstr='#ffb98b', GradientType=1 ); /* IE6-9 */
}
.selection-box {
  display: flex;
  align-items: center;
  margin-bottom: 0.2rem;
  input {
    font-size: 0.3rem;
  }
  .item {
    height: 0.8rem;
    background: #fff;
    border: 1px solid #f5f5f5;
    padding: 0.2rem;
    flex: 1;
    margin-right: 0.2rem;
    border-radius: 0.15rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.28rem;
    input {
      flex: 1;
      width: 100%;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
